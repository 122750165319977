<template>
    <div class="container">
        <div class="row">
            <LoginForm/>
            <IconicTitle/>
        </div>
    </div>
</template>

<script>
    import LoginForm from './LoginForm.vue'
    import IconicTitle from './IconicTitle.vue'

    export default {
        name: 'Login',
        components: {
            LoginForm,
            IconicTitle
        }
    }
</script>

<style>
    div.container {
        max-width: none;
    }
    div.col-sm {
        text-align: center;
        justify-content: center;
    }
    div.container, div.row, div.container div.row {
        height: 100%;
    }
</style>